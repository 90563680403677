<template>
  <Main>
    <div v-if="order != null" class="success-order">
      <div class="success-order__container">
        <h1>Заказ успешно создан</h1>
        <FocusOrder :order="order" :big="true" />
        <div class="success-order__button-group">
          <ButtonMain @click.native="backToEther()" :buttonGrey="true">В эфир</ButtonMain>
          <ButtonMain v-if="order.driver === null" @click.native="assignDriverToOrder()"
            >Назначить водителя</ButtonMain
          >
        </div>
      </div>
    </div>
    <FetchSpinnerModal v-if="loadingProcess" />
  </Main>
</template>

<script>
import { mapState } from "vuex";
import ROUTE from "../constants/routes";

import Main from "../templets/Main.vue";
import FocusOrder from "../components/sections/Ether/EtherFocusOrder.vue";
import ButtonMain from "../components/atoms/buttons/ButtonMain.vue";
import FetchSpinnerModal from "../components/molecules/FetchSpinnerModal.vue";

export default {
  name: "SuccessOrder",
  components: {
    Main,
    FocusOrder,
    ButtonMain,
    FetchSpinnerModal,
  },
  methods: {
    getOrderSucces() {
      const { params } = this.$route;
      const url = `/${params.orderId}`;
      this.$store.dispatch("order/getOrderDetail", url);
    },
    backToEther() {
      this.$router.push({
        path: ROUTE.ETHER_PAGE,
      });
    },
    assignDriverToOrder() {
      this.$router.push({
        path: ROUTE.ETHER_ADD_DRIVER_PAGE.replace(":orderId", this.order.id),
      });
    },
  },
  computed: {
    ...mapState("order", ["order", "loadingProcess"]),
  },
  mounted() {
    this.getOrderSucces();
  },
};
</script>

<style lang="scss" scoped>
.success-order {
  width: 100%;

  &__container {
    padding: 25px 17px;
  }

  h1 {
    padding-bottom: 20px;
  }

  &__button-group {
    padding-top: 20px;

    /deep/.button-main {
      width: 168px;
      height: 48px;
      margin-right: 20px;
    }
  }
}
</style>
